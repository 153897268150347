<template>
  <section class="CNBanner" :class="'image-' + banner.image_position">
    <div class="container">
      <img v-if="banner.image" :src="imgApi +''+ banner.image.url" alt="" class="banner-img">
      <div id="mainBanner" v-bind:style="{'background-image': 'url(' + banner.image + ')'}">
        <h2 v-if="banner.title">{{ trans(banner,'title') }}</h2>
        <p v-if="banner.text">{{ trans(banner,'text') }}</p>
        <div v-if="banner.button">
          <router-link :to="{ name:  'contact', params: { id: 3, name: 'plan-een-gesprek'} }" class="btn" v-if="banner.button.display == true">{{ trans(banner.button,'text') }}</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CNBanner',
  props: {
    banner: {
      type: Object,
      required: true
    }
  },
  computed: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
@import 'sass/variables.sass'
.CNBanner
  background: $abilityBlue
  color: #fff
  position: relative
  overflow: hidden
.container
  padding-top: 90px
@media screen and (min-width: 992px)
  .banner-img
    position: absolute
    right: 0
    bottom: 0
    z-index: -1
    @media screen and (max-width: 1199px)
      top: 50%
      transform: translateY(-50%)
  #mainBanner
    min-height: calc(420px - 90px)
    padding: 66px 0 100px
    display: flex
    flex-direction: column
    justify-content: center
    font-size: 18px
    line-height: 28px
    width: 50%
h2
  font-weight: 500
  font-size: 50px
  line-height: 55px
  color: $lightBlue
p
  margin: 43px 0

@media screen and (max-width: 991px)
  .banner-img
    max-width: calc(100% + 15px)
    position: relative
    right: -15px
    float: right
  .image-fixed
    .container
      height: 40vw
      min-height: 326px
    .banner-img
      position: absolute
      top: 0
      z-index: -1
      height: 100%
      max-width: none
  #mainBanner
    font-size: 14px
    line-height: 22px
    padding-bottom: 50px
    clear: both
  h2
    font-size: 30px
    line-height: 35px
    margin-top: 34px
  p
    margin-top: 20px
    margin-bottom: 30px
</style>

<style lang="sass">
.CNBanner + div section
    margin-top: 0 !important
</style>