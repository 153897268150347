import Vue from 'vue'
import VueRouter from 'vue-router'
import page from '../views/page/index.vue'
import Contact from '../views/page/contact.vue'
import NotFound from '../views/page/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    name: '/',
    path: '/',
    component: page
  },
  {
    name: 'page',
    path: '/page/:id-:name',
    component: page,
    // props: (route) => ({ id: route.params.id }),
    // children: [
    //   {
    //     name: 'contact',
    //     path: '/contact/:name',
    //     component: Contact,
    //     props: (route) => ({ id: route.params.id }),
    //   }
    // ]
  },
  {
    name: 'contact',
    path: '/:id-contact',
    component: Contact,
    // props: (route) => ({ id: route.params.id })
  },
  {
    name: '404',
    path: '/404',
    component: NotFound
  },
  {
    path: '*',
    component: page
  }
]

const router = new VueRouter({
  routes,
  watch:{
    '$route' (to) {
      if(to.currentRoute.meta.reload==true){
        // window.location.reload()
      }
    }
  },
  // hashbang: false,
  // history: true,
  mode: 'history',
  // linkActiveClass: "active"
})

export default router
