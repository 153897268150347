<template>
  <section class="CNListing">
    <div class="container">
      <header>
        <h3>{{ trans(features,'title') }}</h3>
      </header>

      <div class="featuresList">
        <dl>
          <template v-for="(item, index) in features.textblock">
            <dt v-bind:key="index">
              {{ trans(item,'title') }}
            </dt>
            <dd v-bind:key="'A'+index" v-html="trans(item,'text')">
            </dd>
          </template>
        </dl>

        <div class="ClickToAction">
          <template v-for="(item, index) in features.button">
            <router-link class="btn" :to="item.url ? item.url : '/'" :key="index" :class="item.opacity">{{ trans(item,'text') }}</router-link>
          </template>
        </div>
        
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CNListing',
  props: {
    features: {
      type: Object,
      required: true
    }
  },
  computed: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
@import 'sass/variables.sass'
.CNListing
  .container
    padding-bottom: 110px
    @media screen and (max-width: 1199px)
      padding-bottom: 50px
.featuresList
  padding-top: 38px
  padding-left: 10px
  @media screen and (min-width: 992px)
    width: 50%
    padding-left: 0
    margin: 0 auto

dl
  color: $abilityBlue
  // padding-left: 10px
  counter-reset: list
dt
  font-weight: 500
  font-size: 23px
  line-height: 36px
  /* ability-blue */
  position: relative
  padding-bottom: 9px
  &:before
    content: ''
    width: 2px
    height: 24px
    left: -10px
    top: 4px
    /* KBenP pink */
    background: #EC6282
    position: absolute
  @media screen and (min-width: 992px)
    &:after
      content: ''
      position: absolute
      width: 60px
      height: 60px
      left: -101px
      top: -15px
      /* KBenP - light blue */
      background: $lightBlue
      counter-increment: list
      content: "0" counter(list) ""
      text-align: center
      padding-top: 13px
      border-radius: 30px
      box-sizing: border-box
    &:nth-of-type(9) ~ &:after
      counter-increment: list
      content: "" counter(list) ""
dd
  font-size: 18px
  line-height: 28px
  margin-bottom: 68px
  &:last-child
    margin-bottom: 48px

.ClickToAction a
  vertical-align: top

a
  &.light
    color: #FFFFFF
    &:before
      border-right-color: $abilityBlue
  &.dark
    height: 60px
    line-height: 36px;
    margin-left: -169px
    margin-right: 42px
    pointer-events: none
@media screen and (max-width: 991px)
  dt
    font-size: 15px
    line-height: 20px
    &:before
      top: -3px
  dd
    font-size: 14px
    line-height: 18px
    margin-bottom: 27px
  .dark
    display: none
</style>
