<template>
  <section class="CNFeatures">
    <div class="container">
      <header>
        <h3>{{ trans(features,'title') }}</h3>
      </header>

      <div class="row featuresList">
        <div class="col-md-3 col-sm-6 col-xs-12" v-for="(item, index) in features.textblock" :key="index">
          <figure>
            <img :src="imgApi +''+ item.image.url" alt="" class="img-responsive" v-if="item.image">
          </figure>
          <dl>
            <dt>{{ trans(item,'title') }}</dt>
            <dd v-html="trans(item,'text')"></dd>
          </dl>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CNFeatures',
  props: {
    features: {
      type: Object,
      required: true
    }
  },
  computed: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
@import 'sass/variables.sass'
/* KBenP - light blue */
.CNFeatures
  // margin-top: 40px  
  // background: $lightBlue
.container
  padding-bottom: 70px
.featuresList
  padding-top: 34px;
  // padding-left: 13px;
.col-md-3
  padding-bottom: 30px
  + .col-md-3
    padding-left: 0
  @media screen and (max-width: 991px)
    &:nth-child(odd)
      padding-left: 15px
figure
    min-height: 165px
img
  // display: block
  // margin: 0 auto
dl
  // margin-left: -4px
dt
  font-weight: 500
  font-size: 23px
  line-height: 36px

  /* ability-blue */
  color: $abilityBlue

  position: relative
  padding-bottom: 11px
  &:before
    content: ''
    position: absolute
    display: block
    width: 2px
    height: 24px
    left: 0
    top: 5px
    /* KBenP pink */
    background-color: #EC6282
dd
  font-size: 18px
  line-height: 28px
  color: $abilityBlue
dt,dd,figure
  padding-left: 10px

@media screen and (max-width: 991px)
  .container
    padding-bottom: 40px
  .row
    flex-wrap: wrap
  .col-md-3
    flex-basis: 50%
  dt
    font-size: 15px;
    line-height: 20px;
    &:before
      top: -3px;
  dd
    font-size: 14px;
    line-height: 18px;
@media screen and (max-width: 767px)
  .col-md-3
    flex-basis: 100%
    padding-left: 15px !important;
</style>
