<template>
    <div>
				<div v-for="(block, index) in PageContainers" :key="index" class="section"  v-scroll-reveal>
					<CNLogoList v-if="block.type == 'logo_list'" :list="block" :key="index" :class="block.background" />

					<CNContent v-if="block.type == 'content'" :content="block" :key="index" :class="block.background" />
					
					<CNFeatures v-if="block.type == 'features'" :features="block" :key="index" :class="block.background" />

					<CNListing v-if="block.type == 'listing'" :features="block" :key="index" :class="block.background" />
					
					<CNCarousel v-if="block.type == 'carousel'" :slider="block" :key="index" :class="block.background" />
				</div>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'

import CNBanner from '@/components/CNBanner'
import CNLogoList from '@/components/CNLogoList'
import CNContent from '@/components/CNContent'
import CNFeatures from '@/components/CNFeatures'
import CNListing from '@/components/CNListing'
import CNCarousel from '@/components/CNCarousel'
import CNContact from '@/components/CNContact'

export default {
  name: 'CNContainer',
  components: {
    CNBanner,
    CNLogoList,
    CNContent,
    CNFeatures,
    CNListing,
    CNCarousel,
    CNContact
  },
  computed: {
    ...mapState(['PageContainers'])
  }
}
</script>


<style lang="sass" scoped>
</style>