<template>
  <section class="CNContent">
    <div class="container">
      <header>
        <h3>{{ trans(content,'title') }}</h3>
      </header>

      <div class="row">
        <div class="col-md-6 col-push-6" v-if="content.template != 'double column'">
          <img :src="imgApi +''+ content.image.url" alt="" class="img-responsive" v-if="content.image"> 
          <br>
          <br>
        </div>
        <div class="" :class="content.template != 'double column' ? 'col-pull-6 col-md-6' : 'double-column col-md-12'">
          <template v-for="(item, index) in content.textblock">
            <dl :key="index">
              <dt>
                {{ trans(item,'title') }}
              </dt>
              <dd v-html="trans(item,'text')"></dd>
            </dl>
          </template>
        </div>
      </div>
      </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CNContent',
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  computed: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
@import 'sass/variables.sass'
// .CNContent
.container
  padding-bottom: 90px;
.row
  padding-top: 10px
dl,dt,dd
  display: block

dt
  font-weight: 500
  font-size: 23px
  line-height: 36px
  
  position: relative
  padding-left: 10px
  padding-top: 15px
  &:before
    content: ''
    position: absolute
    display: block
    width: 2px
    height: 24px
    left: 0
    top: 20px
    /* KBenP pink */
    background-color: #EC6282

dd
  font-size: 18px
  line-height: 28px

  padding-left: 10px
  padding-top: 11px
  padding-bottom: 15px 

dl
  color: $abilityBlue
  /* padding-top: 24px */

@media screen and (max-width: 991px)
  .container
    padding-bottom: 50px;
  .row
    flex-wrap: wrap
  dt
    font-size: 15px;
    line-height: 20px;
    padding-top: 0;
    &:before
      top: -3px;
  dd
    font-size: 14px;
    line-height: 18px;

@media screen and (min-width: 992px)
  .double-column
    dl
      width: 50%
      float: left

      &:nth-child(odd)
        clear: left
        padding-right: 30px

.img-responsive
  filter: drop-shadow(10px 10px 20px rgba(0, 19, 86, 0.2));
</style>
