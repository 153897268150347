import { mapState } from 'vuex'

// define a mixin object
export default {
  computed: {
		...mapState(['api_lang', 'trans_lang']),
    imgApi: function() {
      return process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API : '/api';
    }
  },
  methods: {
    trans: function (item, key) {
      let value_lang = this.api_lang
      value_lang = value_lang.toUpperCase()
      if(value_lang == 'EN') {
        value_lang = 'UK';
      }
      let trans_key = key + '_' + value_lang;

      if(!item[trans_key]){
        return item[key]
      }
      else{
        return item[trans_key];
      }
    }
  },
  created() {
  }
}
