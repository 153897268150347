import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pages: [],
    page: [],
    PageContainers: [],
    contact: [],
    trans_lang: {'en': 'en', 'nl': 'en-NL'},
    api_lang: 'en'
  },
  mutations: {
    setLang(state, data) {
      let match_lang;
      let obj = this.state.trans_lang

      match_lang = Object.keys(obj).find(key => obj[key].toLowerCase() == data.toLowerCase())

      if(!match_lang) match_lang = data

      state.api_lang = match_lang

      const html = document.documentElement // returns the html tag
      html.setAttribute('lang', data)
    },
    setPages(state, data) {
      state.pages = data
    },
    setPage(state, pageData) {
      state.page = pageData
    },
    setContact(state, componentData) {
      state.contact = componentData
    },
    setPageContainers(state, data) {
      state.PageContainers = data
    }
  },
  actions: {
    getLang({ commit }) {
      let localLang;
      if(typeof window !== 'undefined'){
        localLang = navigator.language
      }else{
        localLang = 'en'
      }
      commit('setLang', localLang)
    },
    updateLang({ commit }, params) {
      commit('setLang', params)
    },
    async getPages({ commit }, params) {
      // console.log('getting pages')
      let res = await axios.get('/pages')
      commit('setPages', res.data)
      // this.dispatch('getPage', params)
    },
    getContact: async ({ commit }) => {
      let res = await axios.get('/contact')
      res.data.form.rows.push({'input': res.data.sales.email, 'label': 'to', 'type': 'default', 'hidden': true})
      var form = res.data
      commit('setContact', form)
    },
    async getPage({ dispatch, commit, state }, params) {
      commit('setPage', null)
      commit('setPageContainers', null)
      let page = false

      if (state.pages.length && params) {
        // console.log('pages length')
        page = state.pages.find(paged => paged.id === params.id)
      }
      if(!page && !isNaN(params.id)){
        // console.log(params.id)
        let res = await axios.get(`/pages/${params.id}`)
        commit('setPage', res.data)
      }
      else if (page){
        // console.log('page exists')
        commit('setPage', page)
      // } else if (params.id) {
      //   console.log('id exist but not page ')
      //   let res = await axios.get(`/pages/${params.id}`)
      //   commit('setPage', res.data)
      } else {
        params.id = 1
        let res = await axios.get(`/pages/${params.id}`)
        commit('setPage', res.data)
        // console.log('else')
        // dispatch('getPages');
        // page = state.pages.find(paged => paged.id === 1)
        // commit('setPage', page)
        // commit('setPage', res.data)
      }
      // await dispatch(getContainers, state.page)

      dispatch('getContainers', state.page);
    },
    async getContainers({commit}, page) {
      const pageContainers = []
      if(page && page.containers || page.data.containers){
        page.containers.forEach(item => {
          let res = axios.get(`/containers/${item.container.id}`)
                      .then(res => {
                        let response  = {
                          'id': res.data.id,
                          'name': res.data.name,
                          'name_UK': res.data.name_UK,
                          'name_NL': res.data.name_NL,
                          'title': res.data.title,
                          'title_UK': res.data.title_UK,
                          'title_NL': res.data.title_NL,
                          'type': item.type,
                          'template': item.template.name,
                          'background': res.data.background,
                          'button': res.data.button,
                          'image': res.data.image,
                          'textblock': res.data.textblock
                        }
                        return  response
                      })
          pageContainers.push(res)
        });
      }
      const queue = await Promise.all(pageContainers)
      commit('setPageContainers', queue)
    }
  },
  modules: {}
})