<template>
	<footer id="footer">
		<div class="container">
			<div class="branding">
				Product of 
			</div>
			<div class="row">
				<div class="col-3">
					<div class="foot-logo">
						<img src="@/assets/kbenp-logo.svg" alt="">
					</div>
					<address>
						Charlotte van Pallandtlaan 18 <br>
						2272 TR Voorburg <br>
						070 300 06 84 <br>
						<a href="">kbenp.nl</a>
					</address>
				</div>
				<div class="col-3">
					<div class="foot-logo">
						<img src="@/assets/smartshore-logo.svg" alt="">
					</div>
					<address>
						Nijverheidsweg 16 A <br>
						3534 AM Utrecht <br>
						030 320 09 77 <br>
						<a href="">smartshore-ability.nl</a>
					</address>
				</div>
				<div class="col-6 hidden-xs">
					<img src="@/assets/confind-foot-logo.svg" alt="">
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'

export default {
	name: 'CNFooter',
	props: {
	  pages: []
	},
	computed: {},
	async mounted () {
	},
	mounted () {
	},
	components: {
	}
}
</script>


<style lang="sass" scoped>
@import 'sass/variables.sass'
footer
	// padding-left: 10px
	// padding-right: 10px
	font-size: 15px
	line-height: 28px
	/* ability-blue */
	color: $abilityBlue
.container
	padding-top: 61px
	padding-bottom: 78px
.branding
	padding-bottom: 24px
	padding-left: 10px
.foot-logo
	padding-bottom: 15px
	min-height: 64px
.row [class*='col-']
	&:first-child
		padding-left: 25px
.col-3
	+ .col-3
		padding-left: 10px
.col-6
	display: flex
	align-items: flex-end
	justify-content: flex-end
address
	color: rgba($abilityBlue, .7)
	position:	relative
	&:before
    content: '';
    width: 2px;
    height: 20px;
    left: -10px;
    top: 3px;

    /* KBenP blue */
    background: #0072BC;
    position: absolute;
	a
		/* KBenP blue */
		color: #0072BC
		&:hover
			text-decoration: underline

@media screen and (max-width: 767px)
	.container
    	padding: 30px 15px
	.row
    	flex-direction: column

	.row [class*='col-']
		padding: 0 25px !important

	address
		font-size: 14px
		line-height: 22px
		padding-bottom: 25px
	.branding
		padding-bottom: 10px
	.foot-logo
		min-height: inherit
		padding-bottom: 0
</style>