<template>
  <section class="CNLogoList">
    <div class="container">
      <ul class="logoList">
        <li v-for="(logo, index) in list.textblock" :key="index">
          <img :src="imgApi + '' + logo.image.url" alt="">
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CNLogoList',
  props: {
    list: {
      type: Object,
      required: true
    }
  },
  computed: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.CNLogoList
  background: url('~@/assets/border-h.svg') center bottom repeat-x
  ul
    display: flex
    flex: 0 0 25%
    align-items: center
    height: 180px
    padding-top: 10px
    li
      flex-grow: 1
    img
      margin: 0 auto
      display: block
      max-width: 90%
    @media screen and (max-width: 991px)
      min-height: 76px
      height: auto
      padding: 20px 0
      li
        flex-basis: 33.33%
        &:nth-child(4)
          display: none
</style>
