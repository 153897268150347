<template>
  <section class="CNCarousel">
    <div class="container">
      <header>
        <h3>{{ trans(slider, 'title') }}</h3>
      </header>
    </div>
      <!-- adjustableHeight -->
      <!-- autoplay -->
      <!-- :spacePadding="40" -->
    <carousel
      :loop="true"
      mouseDrag
      touchDrag
      navigationEnabled
      :perPageCustom="[[320, 1], [990, 2], [1200, 3]]"
      :scrollPerPage="false"
      :paginationEnabled="false"
      navigationNextLabel=""
      navigationPrevLabel=""
      >
      <slide v-for="(slide, index) in slider.textblock" :key="index" :blob="index">
        <div>
          <!-- <div class="slideTitle">{{ slide.name }}</div> -->
          <img :src="imgApi + '' + slide.image.url" alt="">
          <p>{{ trans(slide,'text') }}</p>
        </div>
      </slide>
      <slide v-for="(slide, index) in slider.textblock" :key="'a'+index" :blob="'a'+index">
        <div>
          <div class="slideTitle">{{ trans(slide,'name') }}</div>
          <img :src="imgApi + '' + slide.image.url" alt="">
          <p>{{ trans(slide,'text') }}</p>
        </div>
      </slide>
    </carousel>
    <div class="container btm"></div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: 'CNCarousel',
  components: {
    Carousel,
    Slide
  },
  props: {
    slider: {
      type: Object,
      required: true
    }
  },
  computed: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
@import "sass/variables.sass";

.CNCarousel
  overflow: hidden
.container.btm
  padding-bottom: 130px
  @media screen and (max-width: 1199px)
    padding-bottom: 50px
  
header
  margin-bottom: 55px
  @media screen and (max-width: 991px)
    margin-bottom: 25px
.dark
  /* KBenP - light blue */
  background: rgba($lightBlue, .5)
.VueCarousel
  position: relative
.VueCarousel-slide
  // border: 20px solid rgb(234, 241, 247)
  // border-width: 0 20px
  display: flex
  > div
    background: #FFFFFF
    border-radius: 35px
    padding: 55px 35px 
    margin: 0 20px

    font-family: Sofia Pro
    font-style: normal
    font-weight: normal
    font-size: 18px
    line-height: 28px

    /* abilityBlue */
    color: $abilityBlue
    @media screen and (max-width: 991px)
      padding: 30px 20px
p
  margin-top: 30px
  @media screen and (max-width: 991px)
    font-size: 14px
    line-height: 22px
</style>
<style lang="sass">
.CNCarousel
  .VueCarousel-navigation-button
    // overflow: hidden
    padding: 0 !important
    outline: none !important
    background-color: transparent !important
    height: auto
    overflow: visible
    &:before
      content: url(../assets/arrow-left-round.svg)
      display: inline-block
      width: 50px
      height: 50px
      box-shadow: 0px 2px 4px rgba(0, 19, 86, 0.1)
      border-radius: 25px
      transition: box-shadow .15s linear
      position: static !important
      border: none
      background-color: transparent
    &:hover:before
      box-shadow: 0px 2px 14px rgba(0, 19, 86, 0.3)
  .VueCarousel-navigation-next
    &:before
      transform: scale3d(-1, 1,1)
  @media screen and (min-width: 1200px)
    .VueCarousel-wrapper
      overflow: visible
      // padding-top: 40px
      width: 148vw
      left: 50%
      transform: translateX(-50%)
  @media screen and (min-width: 992px)
    .VueCarousel-navigation,
    .slideTitle
      position: absolute
      top: -72px
      left: 50%
      margin-left: -15vw
      z-index: 1
      .VueCarousel-navigation-button
        margin-left: -20px
    .slideTitle
      margin-left: -10.7vw
      margin-top: -40px
      display: none
  @media screen and (max-width: 991px)
    button.VueCarousel-navigation-button
      position: static !important
      transform: none
      margin-left: 15px !important
      margin-top: 15px !important
</style>