<template>
  <div class="page">
    <CNContact class="banner" />
    <CNContainer />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import CNContact from '@/components/CNContact'
import CNContainer from '@/components/CNContainer'

export default {
  name: 'Contact',
  components: {
    CNContainer,
    CNContact
  },
  computed: {
    ...mapState(['page', 'PageContainers'])
  },
  created () {
    this.updatePage()
  },
  watch:{
    '$route' (to, from){
      this.updatePage()
    }
  },
  mounted () {
    // console.log(this.$route.params)
  },
  methods: {
    updatePage () {
      this.$store.dispatch('getPage', this.$route.params)
    }
  }
}
</script>


<style lang="sass" scoped>
.page
  min-height: 90vh
</style>