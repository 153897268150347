<template>
  <div class="page">
    <CNBanner v-if="page && page.header" :banner="page.header" />
    <CNContainer />
    <CNContact  />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'

import CNBanner from '@/components/CNBanner'
import CNContact from '@/components/CNContact'
import CNContainer from '@/components/CNContainer'

export default {
  name: 'page',
  components: {
    CNBanner,
    CNContainer,
    CNContact
  },
  computed: {
    ...mapState(['page'])
  },
  created () {
    this.updatePage()
  },
  watch:{
    '$route' (to, from){
      this.updatePage()
    }
  },
  methods: {
    updatePage () {
      this.$store.dispatch('getPage', this.$route.params)
    }
  }
}
</script>


<style lang="sass" scoped>
.page
  min-height: 90vh
</style>