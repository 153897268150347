<template>
  <div id="app">
    <CNHeader />
    <router-view/>
    <CNFooter />
  </div>
</template>

<script>
// @ is an alias to /src
import CNHeader from '@/components/layout/CNHeader.vue'
import CNFooter from '@/components/layout/CNFooter.vue'

export default {
  name: 'Confind',
  components: {
    CNHeader,
    CNFooter
  }
}
</script>

<style lang="sass">
@import 'sass/all.sass'

header
  font-family: Sofia Pro
  font-style: normal
  font-weight: 500
  font-size: 35px
  line-height: 36px

  /* ability-blue */
  color: $abilityBlue
</style>
