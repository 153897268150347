<template>
  <div class="NotFound">
    <img alt="Vue logo" src="../../assets/logo.png">
    404
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'NotFound',
  components: {
  }
}
</script>
