import Vue from 'vue'
import App from './App.vue'
import VueMeta from 'vue-meta'
import './mixins/gtm'
import translation from './mixins/translation'
import router from './router'
import store from './store'
import axios from 'axios'
import VueScrollReveal from 'vue-scroll-reveal'

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.prototype.$axios = axios;

Vue.config.productionTip = false

Vue.use(VueScrollReveal, {
  class: 'section', // A CSS class applied to elements with the v-scroll-reveal directive; useful for animation overrides.
  duration: 800,
  scale: 1,
  distance: '300px',
  delay: 250,
  mobile: false
});

Vue.mixin(translation);

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? 
  process.env.VUE_APP_API :
  '/api';

new Vue({
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')
