<template>
	<header id="header">
		<div class="container">
			<router-link class="logo" :to="'/'">
				<img alt="Confind" src="@/assets/logo.svg">
			</router-link>
			<ul>
				<router-link
						:to="{ name:  item.id == 3 ? 'contact' : 'page', params: { id: item.id, name: slugify(trans(item,'name'))} }"
						v-slot="{ href, navigate, isActive, isExactActive }"
						v-for="(item, index) in pages"
						:key="index"
						:class="item.name == 'home' ? 'hide' : ''"
					>
					<li
						:class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active', item.id == 3 ? 'contact' : '', page ? (page.id == item.id ? 'current_page' : '') : '']"
					>
						<a :href="href" @click="navigate">{{ trans(item,'name') }}</a>
					</li>
				</router-link>
				<li class="dropdown">
					<a>{{ api_lang }}</a>
					<dl>
						<dd v-for="(value, key) in trans_lang" :key="value" :class="api_lang == value? 'active': ''" v-on:click="updateLang(value)"><a>{{ key }}</a></dd>
					</dl>
				</li>
			</ul>
		</div>
	</header>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapState } from 'vuex'
import slugify from '@/mixins/slugify'

export default {
	name: 'CNHeader',
	props: {
	//   pages: []
	},
	components: {
	},
	metaInfo() {
		if(this.page){
			return {
				title: this.page.meta_title,
				meta: [
					{ name: 'description', content: this.page.meta_description},
					{ property: 'og:title', content: this.page.meta_title},
					{ property: 'og:site_name', content: 'Confind'},
					{ property: 'og:description', content: this.page.meta_description},
					{property: 'og:type', content: 'website'},
					{property: 'og:url', content: 'https://confind.nl/'},
				]
			}
		}
	},
	mixins: [slugify],
	computed: {
		...mapState(['pages', 'page']),
	},
	async mounted () {
	},
	mounted () {
		this.getPages()
		this.getLang()
	},
	methods: {
		...mapActions([ 'getPages', 'getLang', 'updateLang']),
	},
}
</script>
<style lang="sass" scoped>
@import "sass/variables.sass"

#header
	padding-top: 28px
	position: absolute
	width: 100%
	z-index: 1
.logo
	float: left
	margin-top: 13px
	@media screen and (max-width: 991px)
		img
			width: 90px
			height: 22px
ul
	float: right
	list-style: none
	padding: 0
	margin: 0
	text-align: center
	display: flex
    align-items: center
li
	display: inline-block
	padding-left: 28px
	&.contact
	a
		cursor: pointer
		font-style: normal
		font-weight: 500
		font-size: 18px
		line-height: 18px
		/* identical to box height */
		color: #FFFFFF
		padding: 0 24px
		display: inline-block
	&.current_page a,
	a:hover
		background-color: transparent
		color: #fff
		&:after
			border-bottom: 2px #fff solid
			/* padding: 0 */
			content: ''
			display: block
			margin-top: 3px
	@media screen and (max-width: 991px)
		display: none
		a
			font-size: 14px
.contact
	order: 9
	a
		display: inline-block
		vertical-align: middle
		// width: 192px
		height: 50px
		background: transparent !important
		color: $abilityBlue
		border-radius: 53px
		padding-top: 18px
		overflow: hidden
		position: relative
		&:before
			content: ''
			position: absolute
			top: 0
			left: -100%
			height: 100%
			width: 100%
			background-color: $blueHover
			// background: linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(251,253,124,1) 50%)				
			border-right: 300px solid #FBFD7C
			transition: all .3s
			z-index: -1
	&.current_page a,
	a:hover
		// width: 192px
		height: 50px
		right: 0px
		top: 0px

		color: #fff
		border-radius: 53px
		&:after
			display: none
		&:before
			left: 0
	@media screen and (max-width: 991px)
		display: inline-block
		padding-left: 0
li.dropdown
	position: relative
	display: inline-block
	order: 10
	a
		text-transform: uppercase
	>a
		line-height: 42px
	> a,
	&:hover > a
		&:after
			margin-left: 5px
			content: url(~@/assets/drop.svg) !important
			border: none
			display: inline-block
			line-height: 1px
			position: relative
			top: -2px
	&:hover > a:after
		transform: scaleY(-1)
	dl
		overflow: hidden
		position: absolute
		right: 0
		top: 37px
		min-width: 105px
		/* height: 80px */
		/* left: 1319px */
		/* top: 80px */

		/* white */
		background: #FFFFFF
		border-radius: 15px
		opacity: 0
		pointer-events: none
		transition: all .15s linear
		a
			font-family: Sofia Pro
			font-style: normal
			font-weight: normal
			font-size: 15px
			line-height: 40px
			/* identical to box height */
			text-align: center
			// text-decoration: none !important

			/* KBenP blue */
			color: #0072BC
			display: inline-block
			vertical-align: middle
			/* padding: 12px 0 */
			&:after
				display: none
	dd.active
		background: #D5E3F0
    /* height: 40px */
	&:hover dl
		top: 44px
		opacity: 1
		pointer-events: all
	@media (max-width: 991px)
		padding-left: 0
		padding-right: 0
		> a
			padding-right: 0
</style>
