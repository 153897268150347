<template>
	<section class="CNContact">
		<div class="container">
			<div class="row">
				<div class="col-md-6 sales">
					<h3>{{ trans(contact, 'title') }}</h3>
					<div v-html="trans(contact,'text')" class="contact-text"></div>
					<div class="media" v-if="contact.sales">
						<figure>
							<img :src="imgApi + '' + contact.sales.image.formats.thumbnail.url" alt="">
							<a href="https://www.linkedin.com/in/remment/" target="_blank"><img src="@/assets/linkedin.svg" alt=""></a>
						</figure>
						<figcaption>
							<div class="name">{{ contact.sales.name }}</div>
							<div class="name"><a :href="`mailto:${contact.sales.email}?subject=Ik ben geinteresseerd in meer informatie over Confind`">{{ contact.sales.email }}</a></div>
							<div class="name"><a href="tel:31615371601">+31-615371601</a></div>
						</figcaption>
					</div>
				</div>
				<div class="col-md-6 form" v-if="contact.form">
					<form
						id="contactForm"
						@submit.prevent="checkForm"
						:class="formStatus"
					>
						<div v-if="formStatus == 'thankYou'" >
							<p>{{ trans(contact, 'thank_you_text') }}</p>
							<button class="btn" @click="resetForm()">Terug naar contactformulier</button>
						</div>
						<div v-else>
							<div v-for="(field, index) in contact.form.rows" :key="index">

								<input :type="field.type == 'default' ? 'text' : field.type " :name="field.label" v-model="field.input" :required="field.required" v-if="field.hidden" hidden>
								<div class="form-group" v-else>
									<label for="">{{ trans(field,'label') }}</label>
									<div class="field">
										<input :type="field.type == 'default' ? 'text' : field.type " :name="`${field.label}`" v-model="field.input" v-if="field.type" :required="field.required">
										<textarea :name="field.label" v-model="field.input" v-else :required="field.required"></textarea>
									</div>
								</div>
							</div>
							<footer>
								<div class="form-group">
									<label></label>
									<div class="field">
										<template v-for="(btn, index) in contact.form.button">
											<a :href="`mailto:${contact.sales.email}?subject=Ik ben geinteresseerd in meer informatie over Confind`" :class="btn.opacity" v-if="btn.type == 'link'" type="btn.type" :key="index">{{ trans(btn,'text') }}</a>
											<button v-else :class="btn.opacity" :type="btn.type" :key="index">{{ trans(btn,'text') }}</button>
										</template>
									</div>
								</div>
							</footer>
						</div>
					</form>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'CNContact',
    data: ()=> { 
		return {
			formStatus: String
        }
    },
	computed: {
		...mapState(['contact'])
	},
	mounted () {
		this.$store.dispatch('getContact')
	},
	methods: {
		async checkForm() {
			this.formStatus = 'submitting'
			var postTo	=	'/email/'
			var data	=	this.formData()
			const res = await this.$axios.post( postTo, data)
			if(res) this.formStatus = 'thankYou'
		},
		formData() {
			var form = this.contact.form
			return {
					"to": this.contact.sales.email,
					"from": form.rows.find(field => field.label === 'E-mail adres').input,
					"subject": 'Confind contact form',
					"text": `Naam : ${form.rows.find(field => field.label === "Naam").input}, Telefoon: ${form.rows.find(field => field.label === "Telefoon").input}, Bedrijf: ${form.rows.find(field => field.label === "Bedrijf").input} and Comment: ${form.rows.find(field => field.label === "Comment").input}`
				}
		},
		resetForm() {
			this.formStatus = ''
			this.contact.form.rows.forEach((element, index) => {
				element.input = '';
			});
		}
	}
}
</script>email

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
@import "sass/variables.sass";
.CNContact
	background: $abilityBlue
	/* white */
	color: #fff
	font-weight: normal
	font-size: 18px
	line-height: 28px
	color: #fff
.container
	padding-bottom: 167px
	background: url('~@/assets/border.svg') 15px -18px repeat-y, url('~@/assets/border.svg') 50% -18px repeat-y, url('~@/assets/border.svg') 25% -18px repeat-y, url('~@/assets/border.svg') 75% -18px repeat-y, url('~@/assets/eclipse.svg') 15% 75% no-repeat, url('~@/assets/eclipse1.svg') 103% 85% no-repeat !important
	@media screen and (max-width: 1199px)
    	padding-bottom: 70px
	@media screen and (max-width: 991px)
		background: url('~@/assets/border.svg') 15px -18px repeat-y !important

.banner
	&:after
		display: none
	.container
		padding-top: 110px
		padding-bottom: 60px
		background: url('~@/assets/border-dull.svg') 50% -18px repeat-y !important
		@media screen and (max-width: 991px)
			padding-top: 36px
			background: none !important
	.form
		margin-top: 0

.sales
	padding-top: 59px
	@media screen and (min-width: 992px)
		padding-right: 40px
		margin-left: 10px
h3
	font-style: normal
	font-weight: 500
	font-size: 35px
	line-height: 35px
	margin-bottom: 32px
.contact-text
	opacity: .8
	margin-bottom: 52px
figure
	width: 170px
	max-height: 170px
	border-radius: 15px
	overflow: hidden
	margin-right: 20px
	position: relative
	> img
		width: 100%
		float: left
	a
		position: absolute
		bottom: 10px
		right: 10px
		max-width: 30%
		img
			display: block
			width: 100%

figcaption
	font-weight: 500
	font-size: 18px
	line-height: 35px
	a
		color: #fff
		&:hover
			text-decoration: underline

.media
	display: flex
	align-items: center


.form
	padding: 0
	padding-top: 40px
	@media screen and (min-width: 992px)
		left: -27px
		position: relative
		padding-right: 15px !important
		margin-top: -30px
		padding-top: 0

	form
		@media screen and (min-width: 992px)
			background: $shinyBlue
			box-shadow: 10px 10px 20px rgba(0, 19, 86, 0.2)
			border-radius: 15px
			padding: 50px
			padding-top: 60px
			background: $shinyBlue url('~@/assets/border-dull.svg') 21px -18px repeat-y !important
		@media screen and (max-width: 991px)
			padding: 0 15px
	.thankYou
		min-height: 480px
		font-style: normal
		font-weight: 500
		font-size: 35px
		line-height: 46px
		padding-top: 100px
		background: url('~@/assets/border-dull.svg') 21px -18px repeat-y, url('~@/assets/thankYou.svg') right bottom no-repeat !important
		background-color: $shinyBlue !important
		background-size: contain !important
		.btn
			/* width: 293px; */
			padding: 0 29px 0;
			margin-top: 40px;
			background: $lightBlue;
			display: inline-block;
			outline: none !important;
			&:hover
				background: $blueHover
				color: #fff
	.submitting:not(.thankYou)
		cursor: wait
		.form-group
			pointer-events: none
.form-group
	display: flex
	margin-bottom: 10px

label
	font-weight: normal
	font-size: 15px
	line-height: 28px
	width: 110px
	padding-top: 9px
	position: relative
	@media screen and (min-width: 992px)
		&:before
			content: ''
			display: block
			position: absolute
			width: 2px
			height: 14px
			background: $blueHover
			left: -29px
			top: 15px
.field
	flex-grow: 1
	&:focus-within
input, textarea
	height: 45px
	left: 850px
	top: 4012px
	width: 100%

	/* white */
	background: #fff
	border-radius: 10px
	border: 0
	padding: 0 20px
	outline: none

	font-style: normal
	font-weight: normal
	font-size: 15px
	line-height: 28px

	/* ability-blue */
	color: $abilityBlue

	&:focus
	box-shadow: 0px 0px 0px 1px rgba(0, 114, 188, .3)

textarea
	height: 100px
	padding: 20px

footer .field
	display: flex
	justify-content: space-between
	align-items: center;
.form-group
	a
		color: #fff
		&:hover
			opacity: 0.8
	button
		@media screen and (max-width: 991px)
			margin-bottom: 15px
	.dark
		color: #fff
		&:before
			border-right-color: #EC6282
		&:hover
			&:before
				background-color: $blueHover
footer
	&:after,
	label:before
		display: none
@media screen and (max-width: 991px)
	h3
		font-size: 25px
		line-height: 30px
		margin-bottom: 10px
	.contact-text
		font-size: 14px;
		line-height: 18px;
		margin-bottom: 30px
	.row
		flex-wrap: wrap
		padding: 0 15px

@media screen and (max-width: 767px)
	.form-group
		flex-direction: column;
		label
			font-size: 13px;
			line-height: 20px;
			padding-top: 0;
	footer
		.field
			flex-direction: column-reverse;
			align-items: center;
</style>
